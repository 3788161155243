.rtl,
.ar {
  direction: rtl;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  white-space: pre-wrap;
  word-break: break-word;
}

#overflow-hidden {
  overflow: hidden;
}

[data-aos] {
  pointer-events: none;
}
.aos-animate {
  pointer-events: auto;
  transform: unset !important;
}

.maintenance-section {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
